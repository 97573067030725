import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare global {
    interface Window {
        Canny: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CannyService {
    private appID = '66cdb6e481e20b917d1f52a6'; // Your Canny App ID

    constructor(@Inject(DOCUMENT) private document: Document) { }

    loadCannyScript(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (!this.document.getElementById('canny-jssdk')) {
                const script = this.document.createElement('script');
                script.id = 'canny-jssdk';
                script.src = 'https://canny.io/sdk.js';
                script.async = true;
                script.onload = () => resolve();
                this.document.body.appendChild(script);
            } else {
                resolve();
            }
        });
    }

    initCanny(): void {
        if (typeof window.Canny === 'undefined') {
            window.Canny = function () {
                (window.Canny.q = window.Canny.q || []).push(arguments);
            };
        }
    }

    identifyUser(user: {
        email: string;
        name: string;
        id: string;
        avatarURL?: string;
        created?: Date;
    }): void {
        window.Canny('identify', {
            appID: this.appID,
            user: {
                email: user.email,
                name: user.name,
                id: user.id,
                avatarURL: user.avatarURL,
                created: user.created ? user.created.toISOString() : undefined,
            },
        });
    }
}